    /*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
    /* Core CSS required for Ionic components to work properly */
    
    @import "~@ionic/angular/css/core.css";
    /* Basic CSS for apps built with Ionic */
    
    @import "~@ionic/angular/css/normalize.css";
    @import "~@ionic/angular/css/structure.css";
    @import "~@ionic/angular/css/typography.css";
    @import "~@ionic/angular/css/display.css";
    /* Optional CSS utils that can be commented out */
    
    @import "~@ionic/angular/css/padding.css";
    @import "~@ionic/angular/css/float-elements.css";
    @import "~@ionic/angular/css/text-alignment.css";
    @import "~@ionic/angular/css/text-transformation.css";
    @import "~@ionic/angular/css/flex-utils.css";
    @import "~animate.css/animate.min.css";
    @import "assets/font/fonts.scss";
  //  @import '~@ionic/angular/css/ionic-swiper';
    .ios ion-nav>.ion-page>ion-header>.toolbar.statusbar-padding:first-child {
        background-color: transparent;
    }
    
    ion-content {
        --background: transparent;
        margin-top: env(safe-area-inset-top);
        .container {
            margin-top: 5%;
            width: 95%;
            //  background: rgba($color: #ffffff, $alpha: 1);
            border-radius: 8px;
            padding-bottom: 0px;
            position: relative;
            background-color: transparent !important;
            padding: 5px;
        }
    }
    
    .example-modal {
        align-items: flex-end;
        --border-radius: 10px;
        justify-content: center;
        --min-width: 96% --max-height: 70%;
        --height: 80%;
        --min-height: 50px;
        --max-height: 50%;
        --max-width: 90%;
        --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.8), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        &::part(handle) {
            background: var(--ion-color-light);
            width: 150px;
            height: 6px;
        }
        &::part(content) {
            border-top-right-radius: 10px;
            box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
       //     background: url("../src/assets/imgs/fotos/4.jpg") no-repeat bottom center / cover !important;
             background: transparent no-repeat bottom center / cover !important;
        }
    }
    .center-modal {
        align-items: flex-start;
        --border-radius: 10px;
        justify-content: center;
        --min-width: 96% --max-height: 70%;
        --height: 50%;
        --min-height: 50px;
        --max-height: 50%;
        --max-width: 90%;
        --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.8), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        &::part(handle) {
            background: var(--ion-color-dark);
            width: 150px;
            height: 6px;
        }
        &::part(content) {
            border-top-right-radius: 10px;
            box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
            background: url("../src/assets/imgs/fundo2.jpg") no-repeat bottom center / cover !important;
           // background: url("../src/assets/imgs/fotos/4.jpg") no-repeat bottom center / cover !important;
            // background: transparent no-repeat bottom center / cover !important;
        }
    }
    
    .detail-modal {
        align-items: flex-end;
        --border-radius: 10px;
        justify-content: center;
        --min-width: 96% --max-height: 70%;
        --height: 80%;
        --min-height: 50px;
        --max-height: 90%;
        --max-width: 90%;
        --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.8), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        &::part(handle) {
            background: var(--ion-color-dark);
            width: 150px;
            height: 6px;
        }
        &::part(content) {
            border-top-right-radius: 10px;
            box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
            background: url("../src/assets/imgs/fundo2.jpg") no-repeat bottom center / cover !important;
          //  background: url("../src/assets/imgs/fotos/4.jpg") no-repeat bottom center / cover !important;
        }
    }
    
    .small-modal {
        align-items: flex-end;
        --border-radius: 10px;
        justify-content: center;
        --height: 370px;
        --min-height: 50px;
        --max-width: 90%;
        --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.8), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        &::part(handle) {
            background: var(--ion-color-light);
            width: 150px;
            height: 6px;
        }
        &::part(content) {
            border-top-right-radius: 10px;
            box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
        //    background: url("../src/assets/imgs/fotos/4.jpg") no-repeat bottom center / cover !important;
        }
    }
    
    .cart-modal {
        align-items: flex-end;
        justify-content: center;
        --border-radius: 10px;
        --min-height: 50%;
        --min-width: 96%;
        --max-height: 85%;
        --min-height: 50px;
        --max-width: 50%;
        --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.8), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        &::part(handle) {
            background: var(--ion-color-light);
            width: 150px;
            height: 6px;
        }
        &::part(content) {
            border-top-right-radius: 10px;
            box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
           background: url("../src/assets/imgs/fundo2.jpg") no-repeat bottom center / cover !important;
        }
    }
    
    ion-header {
        background: transparent !important;
        padding-top: 40px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        .grid {
            width: 100%;
            z-index: 100;
            .titulo {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                margin-top: 8px;
                color: var(--ion-color-light);
                font-family: "Roboto", cursive;
                padding: 0px;
                text-align: left;
                text-indent: 0px;
                .titleDesc {
                    position: relative;
                    width: 100%;
                    height: auto;
                    top: 5px;
                    margin-bottom: 10px;
                    font-family: Roboto;
                    font-style: normal;
                    text-align: center;
                    font-weight: bold;
                    font-size: 1rem;
                    line-height: 25px;
                    //border-left: 3px solid var(--ion-color-dark);
                    padding-left: 5px;
                    color: var(--ion-color-light);
                    overflow: hidden;
                    overflow-wrap: normal;
                }
            }
            .close {
                float: right;
                position: absolute;
                width: 10%;
                height: 100%;
                z-index: 100;
                display: none;
                align-items: center;
                justify-content: center;
                ion-button {
                    ion-icon {
                        transform: scale(1.2);
                        border-radius: 50%;
                        background-color: white;
                        color: red;
                    }
                }
            }
            .add {
                float: right;
                width: 10%;
                margin-left: 80%;
                margin-top: 0px;
                height: 100%;
                position: absolute;
                z-index: 100;
                align-items: center;
                justify-content: center;
                // background: #0000ff;
                ion-button {
                    ion-icon {
                        transform: scale(1.2);
                        border-radius: 50%;
                        background-color: white;
                        color: red;
                    }
                }
            }
        }
        div::before {
            display: block;
            width: 100%;
            height: 15px;
            background: red transparent no-repeat;
        }
    }
    
    .alertCancel {
        --background: rgba(var(--ion-color-tertiary-rgb), 1);
        --color: var(--ion-color-dark);
        --backdrop-opacity: 0.2;
        --border-radius: 50px;
        button.alertButton {
            color: white;
        }
    }
    
    .popover-content {
        width: 95%;
    }
    
    ion-item-divider {
        --background: transparent;
        --color: var(--ion-color-light);
        --inner-padding-bottom: 4px;
        --inner-padding-end: 4px;
        --inner-padding-start: 4px;
        --inner-padding-top: 4px;
        --padding-bottom: 4px;
        --padding-end: 4px;
        --padding-start: 4px;
        --padding-top: 4px;
    }
    
    ion-item {
        --background: transparent;
        --color: var(--ion-color-dark);
        --inner-padding-bottom: 4px;
        --inner-padding-end: 4px;
        --inner-padding-start: 4px;
        --inner-padding-top: 4px;
        --padding-bottom: 4px;
        --padding-end: 4px;
        --padding-start: 4px;
        --padding-top: 4px;
        color: var(--ion-color-light);
        ion-label {
            color: var(--ion-color-dark);
            font-weight: bold;
            ion-icon {
                font-weight: bold;
            }
        }
        ion-text {
            color: var(--ion-color-light);
        }
    }
    
    .head {
        background: var(--ion-color-tertiary);
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    ion-list {
        background: transparent;
    }
    
    ion-segment {
        width: 100%;
        height: 70px;
        display: flex;
        gap: 2px;
        --min-height: 60px;
        background-color: transparent;
        width: 100vw;
        z-index: 1000;
        border-radius: 0px;
        background-color: var(--ion-color-light);
    }
    
    ion-segment-button {
        --padding-start: 0px;
        --padding-end: 0px;
        --border-radius: 8px;
        --indicator-color: transparent;
        --background-checked: transparent;
        --color-checked: black;
        --indicator-box-shadow: 0px;
        margin-top: 0px;
        max-width: 55px;
        min-width: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        ion-icon {
            color: #2D303A;
        }
        ion-label {
            color: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            width: 100%;
            background-color: rgba(var(--ion-color-primary-rgb), 0.6);
            font-family: 'OutFit', sans-serif;
        }
        ion-text {
            position: relative;
            margin-top: 0px;
            margin-bottom: 8px;
            color: black;
            font-size: 19px;
            font-family: 'OutFit', sans-serif;
        }
    }
    
    .segment-button-checked {
        --border-radius: 10.91px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        ion-label {
            color: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            width: 100%;
            border: 1px solid var(--ion-color-success);
            background-color: rgba(var(--ion-color-success-rgb), 1);
            font-family: 'OutFit', sans-serif;
            // border: #2D303A 1px solid;
        }
        ion-text {
            position: relative;
            margin-top: 0px;
            margin-bottom: 8px;
            color: black;
            font-size: 19px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            font-family: 'OutFit', sans-serif;
            width: 100%;
            // border: 1px solid var(--ion-color-success);
            color: black !important; // it works properly 
            //  background-color: #2D303A;
        }
        // Tried all of that but nothing work
    }
    
    .invisible {
        display: block;
        visibility: hidden;
        height: 0;
        width: 0;
    }
    
    .error {
        width: 100%;
        background-color: var(--ion-color-light);
        color: var(--ion-color-danger);
        border-radius: 5px;
        text-indent: 0em;
        padding: 3px;
    }
    
    .textRed {
        //   background-color: rgba(var(--ion-color-danger-rgb), 1);
        color: var(--ion-color-danger);
        border-radius: 5px;
        text-indent: 0em;
        padding: 3px;
    }
    
    .alertaInfo {
        width: 90%;
        padding: 5px;
        background-color: #fc6011;
    }
    
    .white-icon {
        color: white;
    }
    /* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
    
    .danger {
        color: rgba(var(--ion-color-danger-rgb), 1);
        // background-color: green;
        text-indent: 0em;
        mat-icon {
            color: #fc6011;
        }
    }
    
    .success {
        color: rgba(var(--ion-color-success-rgb), 1);
        text-indent: 0em;
        mat-icon {
            color: #fc6011;
        }
    }
    
    .mnView {
        // background-color: rgba(var(--ion-color-primary-rgb),0.5);
        color: var(--ion-color-success);
        text-indent: 0em;
        .mat-icon {
            color: var(--ion-color-success);
        }
    }
    
    .mnEdit {
        // background-color: rgba(var(--ion-color-primary-rgb),0.5);
        color: var(--ion-color-primary);
        text-indent: 0em;
        .mat-icon {
            color: var(--ion-color-primary);
        }
    }
    
    .mnCancel {
        // background-color: rgba(var(--ion-color-primary-rgb),0.5);
        color: var(--ion-color-danger);
        text-indent: 0em;
        .mat-icon {
            color: var(--ion-color-danger);
        }
    }
    
    ion-loading.custom-loading {
        backdrop-filter: blur(5px);
        .loading-wrapper {
            background: white !important;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            flex-direction: column;
            border-radius: 10px;
            .text-loading {
                font-size: 1rem;
                font-weight: 700;
                color: black;
            }
        }
    }
    
    .row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .col-3 {
            ion-img {
                padding: 5px;
                animation: spin 1s linear infinite;
            }
        }
        .col-7 {
            padding: 5px;
            // font-family: "Audiowide";
            left: 50%;
            animation: loading 1s linear infinite;
            color: var(--ion-color-dark);
        }
    }
    
    @keyframes spin {
        0% {
            transform: scale(0.5);
            //  transform: scale(0.5);
            opacity: 0.7;
        }
        100% {
            transform: scale(1);
            opacity: 1;
            //    transform: scale(1.0);
        }
    }
    
    @keyframes loading {
        0% {
            opacity: 0.7;
        }
        100% {
            opacity: 1;
        }
    }
    
    mat-form-field {
        width: 80%;
        font-size: 14px;
        mat-label {
            color: var(--ion-color-dark);
        }
        input matInput {
            color: var(--ion-color-dark);
        }
    }
    
    a:link {
        color: var(--ion-color-dark);
        text-decoration: none;
    }
    /* visited link */
    
    a:visited {
        color: var(--ion-color-dark);
        text-decoration: none;
    }
    /* mouse over link */
    
    a:hover {
        color: var(--ion-color-dark);
        text-decoration: none;
    }
    /* selected link */
    
    a:active {
        color: var(--ion-color-dark);
        text-decoration: none;
    }
    
    table {
        position: relative;
        width: 100%;
        box-shadow: none;
        td {
            //  border: 1px solid var(--ion-color-dark);
            font-size: 8px;
            text-transform: uppercase;
            padding: 1px;
        }
        th {
            background-color: rgba(var(--ion-color-medium-rgb), .1);
            text-align: center;
            font-size: 12px;
            color: white;
            align-items: center;
            font-weight: bold;
        }
    }
    
    .mat-row:nth-child(even) {
        background-color: rgba(var(--ion-color-medium-rgb), 0);
        text-align: center;
        color: white;
    }
    
    .mat-row:nth-child(odd) {
        background-color: var(--ion-color-light);
        text-align: center;
    }
    
    .mat-column-name {
        word-wrap: break-word !important;
        white-space: unset !important;
        flex: 0 0 35% !important;
        width: 35% !important;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
    
    .mat-column-status {
        word-wrap: break-word !important;
        white-space: unset !important;
        flex: 0 !important;
        width: 10% !important;
        padding: 0px !important;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        text-align: center;
    }
    
    .mat-column-hourStart {
        word-wrap: break-word !important;
        white-space: unset !important;
        flex: 0 !important;
        width: 15% !important;
        padding: 0px !important;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        text-align: center;
        hyphens: auto;
    }
    
    .mat-column-vacancy {
        word-wrap: break-word !important;
        white-space: unset !important;
        flex: 0 !important;
        width: 15% !important;
        padding: 0px !important;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        text-align: center;
        hyphens: auto;
    }
    
    .mat-column-amount {
        word-wrap: break-word !important;
        white-space: unset !important;
        flex: 0 0 25% !important;
        width: 30% !important;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
    
    .border {
        border-bottom: var(--ion-color-dark) 1px solid;
    }
    
    mat-grid-tile {
        color: var(--ion-color-dark);
    }
    
     ::ng-deep {
        $custom-main-color: transparent;
        $custom-label-color: rgba(0, 0, 0, .6);
        // mat-icon-stepper selected color change
        .mat-step-header .mat-step-icon-selected,
        .mat-step-header .mat-step-icon-state-done,
        .mat-step-header .mat-step-icon-state-edit {
            background-color: $custom-main-color !important;
        }
        // input outline color
        .mat-form-field-appearance-outline .mat-form-field-outline {
            color: $custom-main-color !important;
        }
        // mat-input focused color
        .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
            color: $custom-main-color !important;
        }
        // mat-input error outline color
        .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
            color: $custom-main-color !important;
            opacity: 0.8 !important;
        }
        // mat-input caret color
        .mat-input-element {
            caret-color: $custom-main-color !important;
        }
        // mat-input error caret color
        .mat-form-field-invalid .mat-input-element,
        .mat-warn .mat-input-element {
            caret-color: $custom-main-color !important;
        }
        // mat-label normal state style
        .mat-form-field-label {
            color: red !important;
        }
        // mat-label focused style
        .mat-form-field.mat-focused .mat-form-field-label {
            color: $custom-main-color !important;
        }
        // mat-label error style
        .mat-form-field.mat-form-field-invalid .mat-form-field-label {
            color: $custom-main-color !important;
        }
    }
    
    .example-form {
        width: 100%;
        max-width: 400px;
        color: black;
        margin: 5px;
    }
    
    .today-box {
        background: transparent;
        color: var(--ion-color-dark);
        padding: 17px 0px 0px 0px;
        position: relative;
        margin-bottom: 0px;
        .date-title {
            font-size: 20px;
            margin: 7px 0 0 0;
            letter-spacing: 1px;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            color: white;
            // background-color: #fc6011;
            // text-shadow: 0px 0px 5px rgba(#000, 0.15);
        }
        .add {
            margin: 7px 0 0 0;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            top: 15px;
            width: 40px;
            right: 0%;
            height: 100%;
            color: var(--ion-color-success);
            text-align: center;
            font-weight: 700;
            font-size: 2rem;
            z-index: 100;
            //background-color: var(--ion-color-tertiary);
            ion-icon {
                background-color: rgba(var(--ion-color-light-rgb), 1);
                border-radius: 50%;
                font-size: 1.8rem;
            }
        }
        .plus-icon {
            border: 2px solid rgba(#FFF, 0.6);
            border-radius: 50%;
            box-shadow: 0px 10px 20px -14px #000;
            position: absolute;
            top: 50%;
            height: 50px;
            color: black;
            width: 50px;
            transform: translateY(-50%);
            right: 10px;
            cursor: pointer;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            mat-datepicker-toggle {
                // background: red;
                margin-left: 3px;
                color: #000;
                height: 40px;
            }
            .add {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                color: var(--ion-color-primary);
                text-align: center;
                font-weight: 700;
                font-size: 2rem;
                margin-top: 30%;
                z-index: 100;
                // background-color: var(--ion-color-tertiary);
                ion-icon {
                    background-color: rgba(var(--ion-color-light-rgb), 1);
                    border-radius: 50%;
                    font-size: 2rem;
                }
            }
        }
    }
    
    .mobile-wrapper {
        background: #fff;
        /* relative with .today-box::before*/
        z-index: 1;
        position: relative;
        /*---------*/
        width: 100%;
        min-height: 100%;
        margin: auto;
        padding: 10px 0 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0px 10px 30px -10px #000;
    }
    
    .segment-box {
        background: var(--ion-color-light) !important;
        color: #FFF;
        width: 100%;
        display: flex;
        gap: 5px;
        position: relative;
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        justify-content: center;
    }
    
    .upcoming-events {
        .container {
            h3 {
                color: #333;
                font-size: 25px;
                font-family: OutFit;
                margin-bottom: 30px;
                position: relative;
                text-align: left;
                left: 2px;
                width: 100%;
                right: 20px;
                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    background-color: #333;
                    position: absolute;
                    top: 95%;
                    transform: translatey(-60%);
                    right: 0;
                }
                &::after {
                    font-family: 'Ionicons';
                    color: rgba(0, 0, 0, 0.1);
                    vertical-align: middle;
                    font-size: 36px;
                    font-weight: 100;
                    display: inline-block;
                    background: transparent;
                    width: 38px;
                    text-align: center;
                    position: absolute;
                    left: 130px;
                    top: 0px;
                }
            }
            .events-wrapper {
                margin-bottom: 30px;
                .event {
                    position: relative;
                    margin-bottom: 25px;
                    padding-left: 3px;
                    cursor: pointer;
                    .event__icon {
                        width: 8%;
                        margin: 0;
                        color: #555;
                        img {
                            height: 18px;
                        }
                        letter-spacing: 1px;
                    }
                    .event__point {
                        position: absolute;
                        top: 0PX;
                        left: 40px, ;
                        width: 70%;
                        margin: 0;
                        color: #555;
                        font: {
                            size: 15px;
                            weight: 800;
                        }
                        letter-spacing: 1px;
                    }
                    .event__vagas {
                        position: absolute;
                        top: 5px;
                        right: 0;
                        color: #999;
                        font: {
                            size: 10px;
                            weight: 800;
                            style: italic;
                        }
                    }
                    .event__free {
                        position: absolute;
                        top: 15px;
                        right: 0;
                        color: #999;
                        font: {
                            size: 10px;
                            weight: 800;
                            style: italic;
                        }
                    }
                    .event__description {
                        margin-top: 3px;
                        font-size: 12px;
                        width: 100%;
                        border-radius: 1ex;
                        font-weight: 300;
                        ol {
                            counter-reset: li;
                            list-style: none;
                            padding: 0;
                            text-shadow: 0 1px 0 rgba(255, 255, 255, .1);
                        }
                        ol li {
                            position: relative;
                            display: block;
                            padding: .4em .4em .4em 2em;
                            margin: .0em 0;
                            background: rgba(#f9dd94, 0.3);
                            color: black;
                            text-decoration: none;
                            border-radius: .3em;
                            transition: .3s ease-out;
                        }
                        .content-row {
                            height: 100%;
                            width: 100%;
                            .span {
                                position: relative;
                                width: 95%;
                                left: 10px;
                                line-height: 2em;
                            }
                            .item-save {
                                margin-left: 30px;
                                right: 5%;
                                font-size: 1.3rem;
                            }
                        }
                        ol li .span {
                            position: relative;
                            width: 90%;
                            max-width: 300px;
                            padding: 0em 0em 0em 0em;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        ol li:before {
                            content: counter(li);
                            counter-increment: li;
                            position: absolute;
                            left: -1.3em;
                            top: 50%;
                            margin-top: -1.7em;
                            background: #f9dd94;
                            height: 3em;
                            width: 3em;
                            line-height: 3em;
                            border: .3em solid #fff;
                            text-align: center;
                            font-weight: bold;
                            border-radius: 2em;
                            transition: all .3s ease-out;
                        }
                    }
                    &.active {
                        background: #e8e8e8;
                        padding: 17px 0 5px 60px;
                        margin-bottom: 38px;
                        border-radius: 5px;
                        &::after {
                            content: "";
                            display: block;
                            width: 90%;
                            height: 10px;
                            background: #fff;
                            border: 2px solid #ddd;
                            border-top: 0;
                            border-radius: 0 0 5px 5px;
                            position: absolute;
                            bottom: -10px;
                            left: 50%;
                            transform: translatex(-50%);
                        }
                        i {
                            position: absolute;
                            left: 25px;
                            top: 17px;
                        }
                        .event__description {
                            &::before,
                            &::after {
                                content: "\f3d1";
                                font-family: 'Ionicons';
                                font-size: 32px;
                                display: inline-block;
                                color: #919294;
                                text-align: center;
                                position: absolute;
                                right: 30px;
                                top: 50%;
                                transform: translateY(-50%);
                                cursor: pointer;
                            }
                            &::before {
                                right: 45px;
                                font-size: 22px;
                                transition: all 550ms ease;
                                transition-timing-function: cubic-bezier(0.05, 1.8, 1, 1.57);
                            }
                        }
                        &:hover .event__description::before {
                            transform: translate(15px, -12px);
                        }
                    }
                }
            }
        }
    }
    
    .avatar {
        flex-direction: column;
        color: #fff;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        width: 100%;
        height: 100%;
        font-weight: 600;
        background-color: var(--ion-color-medium);
    }
    
    .example-headers-align {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: black;
        max-height: 60px;
        background: white !important;
        padding: 0px;
        font-size: 14px;
    }
    
    .example-headers-align-2 {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: black;
        max-height: 60px;
        background: white !important;
        padding: 0px;
        font-size: 14px;
    }
    
    .text-label-strong {
        width: 100%;
        text-align: center;
        font-weight: bold;
    }
    
    .example-headers-grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: black;
        max-height: 60px;
        background: blue !important;
        padding: 0px;
        font-size: 14px;
        mat-radio-group {
            background: red;
            width: 100%;
            mat-radion-button {
                background-color: #555;
                width: 33%;
            }
        }
    }
    
    .example-checkbox-align {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 40px;
        max-height: 60px;
        padding: 2px;
        font-size: 12px;
        gap: 5px;
        background: var(--ion-color-light);
        .checkbox {
            width: 20%;
            color: var(--ion-color-dark);
            text-align: center;
        }
        .text-checkbox {
            width: 30%;
            color: var(--ion-color-dark);
            text-align: left;
        }
        .text-name {
            width: 50%;
            color: var(--ion-color-dark);
            text-align: left;
        }
    }
    
    .example-form {
        width: 100%;
        max-width: 50%;
        margin: 5px;
        color: black;
        //   border: 1px solid orange;
    }
    
    .detail-invoice {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 14px;
        gap: 14px;
        position: relative;
        width: 386px;
        height: 63px;
        background: rgba(#ffffff, 1);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(2px);
        filter: drop-shadow(0px 4px 10px rgba(10, 10, 10, 0.67));
        border-radius: 12px;
    }
    
    .detail-invoice .icon {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        left: 3.63%;
        right: 86.53%;
        top: 19.84%;
        bottom: 19.84%;
        background: #E7EFEC;
        border-radius: 50%;
    }
    
    .options {
        font-size: 10px;
        display: flex;
        font-weight: 600;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
        background: rgba(var(--ion-color-primary-rgb), 0.5);
        border-radius: 5px;
        margin: 3px;
    }
    .options-g {
        font-size: 10px;
        display: flex;
        font-weight: 600;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
        background: rgba(var(--ion-color-success-rgb), 0.5);
        border-radius: 5px;
        margin: 3px;
    }
    

    .options2 {
        font-size: 10px;
        margin: 3px;
        display: flex;
        font-weight: 600;
        color: black;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: rgba(var(--ion-color-danger-rgb), 0.5);
        border-radius: 5px;
    }
    .options3 {
        font-size: 10px;
        margin: 3px;
        display: flex;
        font-weight: 600;
        color: black;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: rgba(var(--ion-color-light-rgb), 0.5);
        border-radius: 5px;
    }
    
    ion-item-sliding {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: none;
        gap: 0px;
        width: 100%;
        height: 60px;
        border-bottom: none;
        ion-item {
            width: 100%;
            height: 60px;
            //  background-color: #fc6011;
            .icon {
                margin-top: -5px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: -12px;
                min-height: 38px;
                min-width: 38px;
                max-width: 38px;
            }
            .middle {
                width: 300px !important;
                height: 70px !important;
            }
            .detail-body {
                width: 190px !important;
            }
            .mount {
                width: 70px !important;
                font-size: 14px !important;
            }
        }
    }
    
    ion-item::part(native) {
        background: transparent;
        color: black;
        border-color: none;
        font-size: 12px;
        border-style: none;
        border-width: none;
        border-radius: none;
    }
    
    ion-item::part(detail-icon) {
        color: black;
        opacity: 1;
        font-size: 20px;
        border-radius: none;
    }
    
    .detail-invoice .middle {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        position: absolute;
        width: 320px;
        height: 60px;
        left: calc(50% - 250px/2 - 1px);
        top: calc(50% - 60px/2 + 0.05px);
        //background-color: green;
        .detail-body {
            /* Auto layout */
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 2px;
            width: 210px;
            height: 60px;
            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        .title-mid {
            font-family: 'OutFit';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            color: #222523;
            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        .date-mid {
            height: 14px;
            font-family: 'OutFit';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            /* identical to box height */
            letter-spacing: 1px;
            color: #929795;
            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;
        }
        .status-mid {
            height: 14px;
            font-family: 'OutFit';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            /* identical to box height */
            letter-spacing: 1px;
            color: #929795;
            /* Inside auto layout */
            flex: none;
            order: 2;
            flex-grow: 0;
        }
        .mount {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px;
            gap: 5px;
            width: 91px;
            height: 46px;
            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;
            font-size: 1rem;
            font-family: 'OutFit';
            font-style: normal;
            font-weight: 700;
            line-height: 23px;
            text-align: right;
            letter-spacing: 1px;
        }
    }
    
    .header {
        position: fixed;
        margin-top: 0px;
        width: 100%;
        z-index: 100;
        background: white !important;
        order: 0;
        .head-img {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            height: var(--height-top-img-header);
            height: 18vh;
            width: 100%;
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(2px);
            .textHeader {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 50;
                font-family: 'OutFit', sans-serif;
                font-weight: bold;
                font-size: 3.2rem;
                text-transform: uppercase;
                backdrop-filter: blur(4px);
                -webkit-backdrop-filter: blur(2px);
                color: var(--ion-color-light);
                background: rgba(var(--ion-color-dark-rgb), 0.25);
                filter: opacity(80%);
                /* width and color */
            }
            img {
                object-fit: cover;
                /* Do not scale the image */
                object-position: center;
                /* Center the image within the element */
                height: var(--height-top-img-header);
                width: 100%;
                height: 100%;
                opacity: 0.8;
            }
        }
        .head {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 225px;
            img {
                object-fit: cover;
                /* Do not scale the image */
                object-position: 10% 10%;
                /* Center the image within the element */
                height: var(--height-top-img-header);
                width: 100%;
                height: 100%;
                opacity: 0.8;
            }
            .head-img {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                width: 100%;
                backdrop-filter: blur(4px);
                -webkit-backdrop-filter: blur(2px);
                .textHeader {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    position: absolute;
                    height: 225px;
                    width: 100%;
                    z-index: 50;
                    font-family: 'OutFit', sans-serif;
                    font-weight: bold;
                    font-size: 3.2rem;
                    text-transform: uppercase;
                    color: var(--ion-color-dark);
                    background: rgba(var(--ion-color-dark-rgb), 0);
                    filter: opacity(100%);
                    /* width and color */
                }
            }
            .segment {
                width: 100%;
                display: flex;
                background-color: rgba(var(--ion-color-light-rgb), 1) !important;
            }
            .admin {
                position: absolute;
                margin-top: 0vh;
                left: 80%;
                width: 80%;
                text-align: left;
                z-index: 102;
                //background: red;
                div {
                    //   background-color: rgba(var(--ion-color-dark-rgb), 0.01);
                    // -webkit-backdrop-filter: blur(2px);
                    backdrop-filter: blur(2px);
                    height: 40px;
                    width: 100%;
                    float: right;
                    img {
                        height: 40px;
                        width: 40px;
                        background-color: white;
                        padding: 5px;
                        margin-right: 20px;
                        border-radius: 50%;
                        box-shadow: 0px 3px 3px 0px var(--ion-color-dark), 0px 0px 5px 0px rgba(var(--ion-color-dark-rgb), 1);
                    }
                }
            }
        }
    }
    
    .Cancelado {
        background: linear-gradient(135deg, var(--ion-color-danger) 0%, rgba(var(--ion-color-danger-rgb), 0.67) 93.03%) !important;
        color: var(--ion-color-light) !important;
        padding: 3px;
    }
    
    .Cancelada {
        background: linear-gradient(135deg, var(--ion-color-danger) 0%, rgba(var(--ion-color-danger-rgb), 0.67) 93.03%) !important;
        color: var(--ion-color-light) !important;
        padding: 3px;
    }
    
    .Confirmado {
        background: linear-gradient(135deg, var(--ion-color-success) 0%, rgba(var(--ion-color-success-rgb), 0.67) 93.03%) !important;
        color: var(--ion-color-dark) !important;
    }
    
    .Gerada {
        background: linear-gradient(135deg, rgba(var(--ion-color-primary-rgb), 1) 50%, rgba(var(--ion-color-primary-rgb), 1) 100%) !important;
        color: var(--ion-color-dark) !important;
        padding: 3px;
        font-weight: bold;
        font-size: 25px;
    }
    
    .Paga {
        background: linear-gradient(135deg, var(--ion-color-success) 0%, rgba(var(--ion-color-success-rgb), 0.67) 93.03%) !important;
    }
    
    .Vencida {
        background: linear-gradient(135deg, var(--ion-color-danger) 0%, rgba(var(--ion-color-danger-rgb), 0.67) 93.03%) !important;
    }
    
    .content-body {
        margin-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
        background-color: white;
        color: black;
        //  border: 1px solid green;
    }
    
    .full-width {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        ion-button {
            width: 80%;
            border: var(--ion-color-medium);
            background-color: var(--ion-color-light);
            border-radius: 10px;
        }
    }
    
    .divider {
        /* minor cosmetics */
        display: table;
        font-size: 1rem;
        text-align: center;
        width: 100%;
        /* divider width */
        margin: 10px auto;
        color: var(--ion-color-light);
        /* spacing above/below */
    }
    
    .divider span {
        display: table-cell;
        position: relative;
        color: var(--ion-color-light);
    }
    
    .divider span:first-child,
    .divider span:last-child {
        width: 50%;
        top: 13px;
        /* adjust vertical align */
        -moz-background-size: 100% 2px;
        /* line width */
        background-size: 100% 2px;
        /* line width */
        background-position: 0 0, 0 100%;
        background-repeat: no-repeat;
        color: var(--ion-color-light);
    }
    
    .divider span:first-child {
        /* color changes in here */
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(var(--ion-color-light)));
        background-image: -webkit-linear-gradient(180deg, transparent, var(--ion-color-light));
        background-image: -moz-linear-gradient(180deg, transparent, var(--ion-color-light));
        background-image: -o-linear-gradient(180deg, transparent, var(--ion-color-light));
        background-image: linear-gradient(90deg, transparent, var(--ion-color-light));
    }
    
    .divider span:nth-child(2) {
        color: var(--ion-color-light);
        padding: 0px 5px;
        width: auto;
        white-space: nowrap;
        top: 4px;
    }
    
    .divider span:last-child {
        /* color changes in here */
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(var(--ion-color-light)), to(transparent));
        background-image: -webkit-linear-gradient(180deg, var(--ion-color-light), transparent);
        background-image: -moz-linear-gradient(180deg, var(--ion-color-light), transparent);
        background-image: -o-linear-gradient(180deg, var(--ion-color-light), transparent);
        background-image: linear-gradient(90deg, var(--ion-color-light), transparent);
    }
    
    .content-row {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        width: 100%;
    }
    
    .example-margin {
        margin: 0 10px;
    }
    
    .text-right {
        text-align: right
    }

    
    .schedule-row {
        //   background-color: aqua;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 5px;
        position: relative;
        width: 100%;
        min-width: 350px;
        height: 50px;
    }
    
    .line {
        content: "";
        height: 50px;
        border: 2px solid var(--ion-color-dark);
        border-radius: 5px;
        // transform: rotate(90deg);
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    
    .schedule-row:nth-child(2n) .line {
        border-color: rgba(var(--ion-color-primary-rgb), 0.7);
    }
    
    .schedule-row:nth-child(2n) .date {
        color: rgba(var(--ion-color-primary-rgb), 0.7);
    }
    
    .schedule-row:nth-child(2n) .schedule-body .title {
        color: rgba(var(--ion-color-primary-rgb), 0.7);
    }
    
    .schedule-row:nth-child(2n-1) .line {
        border-color: var(--ion-color-primary);
    }
    
    .date {
        /* Auto layout */
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 0px;
        width: 50px;
        height: 50px;
        color: var(--ion-color-primary);
        .day {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50px;
            height: 35px;
            font-family: 'OutFit';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 30px;
            /* or 36px */
            text-align: center;
            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        .month {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50px;
            height: 15px;
            font-family: 'OutFit';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            /* or 24px */
            display: flex;
            align-items: center;
            text-align: center;
            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }
    
    .schedule-row .schedule-body {
        /* Auto layout */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 1px;
        padding: 0px;
        gap: 1px;
        width: 280px;
        height: 50px;
        padding: 0px 10px 0px 10px;
        /* Inside auto layout */
        flex: none;
        order: 2;
        flex-grow: 0;
        .title {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 3px 0px;
            gap: 3px;
            width: 100%;
            height: 18px;
            text-transform: uppercase;
            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
            font-family: 'OutFit';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            /* identical to box height, or 16px */
            display: flex;
            align-items: center;
            letter-spacing: -0.02em;
            color: var(--ion-color-primary);
        }
        .hour {
            width: 100%;
            height: 15px;
            font-family: 'OutFit';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            /* or 16px */
            display: flex;
            align-items: center;
            letter-spacing: -0.02em;
            color: black;
            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;
        }
        .status {
            font-family: 'OutFit';
            /* or 16px */
            display: flex;
            align-items: center;
            color: #949191;
            width: 100%;
            /* Inside auto layout */
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            /* or 16px */
            display: flex;
            align-items: center;
            letter-spacing: -0.02em;
            /* Inside auto layout */
            flex: none;
            order: 2;
            flex-grow: 0;
        }
    }
    
    .schedule-row .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 25px;
        height: 33px;
        /* Inside auto layout */
        flex: none;
        order: 3;
        flex-grow: 0;
    }
    
    .text-Cancelado {
        color: var(--ion-color-danger) !important;
        text-decoration: line-through;
    }
    
    .border-Cancelado {
        border: 3px solid var(--ion-color-danger) !important;
    }
    
    .text-Gerada {
        color: var(--ion-color-primary) !important;
    }
    
    .text-Paga {
        color: var(--ion-color-success) !important;
    }
    
    .mainBoard {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 10px;
        gap: 5px;
        position: relative;
        width: 100%;
    }
    
    .item-mainboard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: transparent;
        border-radius: 8px;
        width: calc(50% - 10px);
        max-width: 220px;
        min-width: none;
        height: 120px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
    
    .inner-item {
        width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 9px;
    }
    
    .inner-item .title {
        font-family: 'OutFit';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: white;
    }

    .admin {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 30px;
        right: 25px;
        border-radius: 50%;
        padding: 5px;
        z-index: 100;
        
        ion-avatar{
            background-color: white;
            width: 40px;
            height: 40px;
            ion-icon{
                width: 40px;
                height: 40px;
                color: black;
            }
        }
        
    
    }